<template>
  <v-container class="mb-10 mt-20">
    <div class="px-15">
      <p :style="`color: ${titleColor};`" class="mb-15 text-h5 text-center">架構規劃與功能設計流程</p>
      <v-img :src="src" contain max-width="930" class="mx-auto my-20"></v-img>
    </div>
  </v-container>
</template>

<script>
export default {
  props: {
    src: {
      type: String,
    },
    titleColor: {
      type: String,
      default: '#A7CDFF'
    }
  },
};
</script>